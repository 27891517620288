@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color: #346ac3;
  --thumb: #ccc;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: var(--thumb);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color);
  border-radius: 14px;
  border: 1px solid var(--color);
}

body {
  font-family: 'Fira Sans', sans-serif;
}

.scroll {
  scrollbar-width: 100%;
  scrollbar-color: var(--color);
}

/* Chrome, Edge, and Safari */
.scroll::-webkit-scrollbar {
  height: 5px;
}

.scroll::-webkit-scrollbar-track {
  background: var(--thumb);
  /* border-radius: 5px; */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--color);
  border-radius: 14px;
  border: 1px solid var(--color);
}
